@tailwind base;
@tailwind components;
@tailwind utilities;


.hero-section {
 background: rgba(0, 0, 0, 0.8) url('/public/hero.jpg');
 background-position: 10% 20%;
 background-size: cover;
 background-blend-mode: darken;
 background-repeat: no-repeat;
}


.active {
 display: flex;

 flex-direction: Column;
 justify-content: flex-start;
 align-items: center;
z-index:1;
 border-bottom: 1px solid white;
 border-right: 1px solid white;

 background-color: darkgoldenrod ;
 position: absolute;
 padding-top: 40px;
 top: 65px;
 width: 100%;
 height: 100%;
 right: 20%;
 transition-duration: 2s;
 transition: 3s ease-in-out;
 box-shadow: inset;
}

.Contact-Hero{
 background: rgba(10, 10, 10,0.9) url("/public/nmi22.jpg");
 background-repeat: no-repeat;
 background-position: center;
 background-size: cover;
 background-blend-mode: darken;


}